<template>
  <div class="mb-2 p-2">
    <table class="table table-sm table-bordered">
      <thead>
        <!-- <tr>
          <th colspan="4" class="text-center"><h4 class="text-primary mb-0">{{ groupName(data.groupId) }}</h4></th>
        </tr> -->
        <tr class="bg-light text-dark">
          <th width="40%" class="text-center">ประเภท</th>
          <th width="20%" class="text-center">ขั้นต่ำ</th>
          <th width="20%" class="text-center">สูงสุด</th>
          <th width="20%" class="text-center">สูงสุดต่อเลข</th>
        </tr>
        <tr class="bg-light">
          <th></th>
          <th><b-form-input type="number" size="sm" v-model="inputAll.min" :number="true"></b-form-input></th>
          <th><b-form-input type="number" size="sm" v-model="inputAll.max" :number="true"></b-form-input></th>
          <th><b-form-input type="number" size="sm" v-model="inputAll.maxPerNumber" :number="true"></b-form-input></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="bet in marketOpenBets" :key="bet.code">
          <td class="text-center">{{ bet.text }}</td>
          <td>
            <b-input-group size="sm" :append="toString(refSetting.betLimit[bet.code].min)">
              <b-form-input v-model="data.betLimit[bet.code].min" type="number" size="sm" :number="true"></b-form-input>
            </b-input-group>
          </td>
          <td>
            <b-input-group size="sm" :append="toString(refSetting.betLimit[bet.code].max)">
              <b-form-input v-model="data.betLimit[bet.code].max" type="number" size="sm" :number="true"></b-form-input>
            </b-input-group>
          </td>
          <td>
            <b-input-group size="sm" :append="toString(refSetting.betLimit[bet.code].maxPerNumber)">
              <b-form-input v-model="data.betLimit[bet.code].maxPerNumber" type="number" size="sm" :number="true"></b-form-input>
            </b-input-group>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { marketOpenBets } from '@/configs/market.config'

export default {
  name: 'AccountSettingLimitTable',
  props: ['data', 'refSetting'],
  data() {
    return {
      inputAll: {
        min: '',
        max: '',
        maxPerNumber: ''
      }
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    groupId() {
      return this.data.groupId
    },
    min() {
      return this.inputAll.min
    },
    max() {
      return this.inputAll.max
    },
    maxPerNumber() {
      return this.inputAll.maxPerNumber
    }
  },
  watch: {
    groupId() {
      this.inputAll = {
        min: '',
        max: '',
        maxPerNumber: ''
      }
    },
    min() {
      if(this.inputAll.min > 0) {
        this.marketOpenBets.forEach((bet)=>{
          this.data.betLimit[bet.code].min = this.inputAll.min
        })
      }
    },
    max() {
      if(this.inputAll.max > 0) {
        this.marketOpenBets.forEach((bet)=>{
          this.data.betLimit[bet.code].max = this.inputAll.max
        })
      }
    },
    maxPerNumber() {
      if(this.inputAll.maxPerNumber > 0) {
        this.marketOpenBets.forEach((bet)=>{
          if(this.inputAll.maxPerNumber > this.data.betLimit[bet.code].min) {
            this.data.betLimit[bet.code].maxPerNumber = this.inputAll.maxPerNumber
          }
        })
      }
    }
  },
  methods: {
    groupName(groupId) {
      let group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.groupTitle
      }else{
        return groupId
      }
    },
    toString(number) {
      if(number)
        return parseInt(number).toString()
      return ''
    }
  },
  mounted() {
    console.log(this.data.betLimit.runBottom.min)
  }
}
</script>
<style lang="scss" scoped>
.table {
  td {
    vertical-align: middle;
  }
  tbody {
    background-color: #FFF;
  }
}
</style>
