<template>
  <div class="card-body p-2">
    <table class="table table-hover mb-0">
      <thead>
        <tr>
          <th width="20%" class="text-left">เปิด/ปิด</th>
          <th class="text-left">กลุ่มหวย</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="group in data" v-if="isGroupActive(group.groupId)">
          <td class="text-left p-2">
            <b-form-checkbox v-model="group.isAvailable" name="check-button" :class="[group.isAvailable ? 'text-success' : 'text-danger']"> <span>{{ group.isAvailable ? 'เปิด' : 'ปิด' }}</span> </b-form-checkbox>
          </td>
          <td class="text-left p-2">{{ groupName(group.groupId) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'NewAccountSettingGroup',
  props: ['data'],
  methods: {
    groupName(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.groupTitle
      }else{
        return groupId
      }
    },
    isGroupActive(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      return group?.status === 'Active'
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  th {
    border-top: 0;
  }
}
</style>
