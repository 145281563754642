<template>
  <div class="py-2">
    <div class="p-1 text-left">
      <button
        class="btn mx-1"
        v-for="group in data"
        v-if="group.isAvailable && isGroupActive(group.groupId)"
        :key="group.groupId"
        :class="[{'btn-primary':selectGroup.groupId===group.groupId}, {'btn-light text-primary':selectGroup.groupId!==group.groupId}]"
        @click="selectGroup=group"
      >{{ groupName(group.groupId) }}</button>
    </div>
    <AccountSettingLimitTable v-if="selectGroup.groupId" :data="selectGroup" :ref-setting="refSetting[selectGroup.groupId]" />
    <div v-if="!isHasOpened">ไม่มีกลุ่มเปิด</div>
  </div>
</template>
<script>
import AccountSettingLimitTable from './AccountSettingLimitTable'
export default {
  name: 'NewAccountSettingLimit',
  components: {
    AccountSettingLimitTable
  },
  props: ['data', 'refSetting'],
  data() {
    return {
      selectGroup: {
        groupId: null
      }
    }
  },
  computed: {
    isHasOpened() {
      if(!this.data)
        return false

      const openGroups = []
      for(const [key, group] of Object.entries(this.data)) {
        if(group.isAvailable) {
          openGroups.push(group)
        }
      }

      return openGroups.length > 0
    }
  },
  methods: {
    groupName(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.groupTitle
      }else{
        return groupId
      }
    },
    isGroupActive(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId && g.groupType === 'single'
      })

      return group?.status === 'Active'
    }
  },
  mounted() {
    for (const groupId in this.data) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(this.data[groupId].isAvailable && group?.status === 'Active') {
        this.selectGroup = this.data[groupId]
        break;
      }
    }
  }
}
</script>
