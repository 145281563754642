<template>
  <div class="p-2">
    <table class="table table-sm table-bordered mb-0">
      <thead>
        <!-- <tr>
          <th :colspan="marketOpenBets.length + 3" class="text-center"><h4 class="text-success mb-0">{{ groupName(data.groupId) }}</h4></th>
        </tr> -->
        <tr class="bg-light text-dark">
          <th width="10%" class="text-center">อัตราจ่าย</th>
          <th width="10%" class="text-center">เปิด/ปิด</th>
          <th width="10%" class="text-center">#</th>
          <th class="text-center" v-for="bet in marketOpenBets" :key="bet.code">{{ bet.text }}</th>
        </tr>
      </thead>
      <tbody
        v-for="rate in payrates"
        :key="rate.rateId"
        v-if="refSetting.payRate[rate.rateId].isAvailable && isGlobalActivate(rate.rateId)">
        <tr>
          <td rowspan="2" class="text-center">{{ rateName(rate.rateId) }}</td>
          <td rowspan="2" class="text-center">
            <b-form-checkbox
              v-model="rate.isAvailable"
              name="check-button"
              :class="[rate.isAvailable ? 'text-success' : 'text-danger']"
              :disabled="false"
            >
              <span>{{ rate.isAvailable ? 'เปิด' : 'ปิด' }}</span>
            </b-form-checkbox>
          </td>
          <td class="text-center">จ่าย</td>
          <td class="text-center" v-for="bet in marketOpenBets" :key="bet.code">
            <b-input-group v-if="rate.rates[bet.code].isAvailable" size="sm" :append="toString(refSetting.payRate[rate.rateId].rates[bet.code].pay)">
              <b-form-input type="number" size="sm" class="text-center" v-model="rate.rates[bet.code].pay" :disabled="!rate.isAvailable" :number="true"></b-form-input>
            </b-input-group>
            <span v-else class="text-danger">ปิด</span>
          </td>
        </tr>
        <tr>
          <td class="text-center">ส่วนลด (%)</td>
          <td class="text-center" v-for="bet in marketOpenBets" :key="bet.code">
            <b-input-group v-if="rate.rates[bet.code].isAvailable" size="sm" :append="toString(refSetting.payRate[rate.rateId].rates[bet.code].discount)">
              <b-form-input type="number" size="sm" class="text-center" v-model="rate.rates[bet.code].discount" :disabled="!rate.isAvailable || !rate.rates[bet.code].isAvailable" :number="true"></b-form-input>
            </b-input-group>
            <span v-else class="text-danger">ปิด</span>
          </td>
        </tr>
      </tbody>
      <tbody v-if="!hasData">
        <tr>
          <td :colspan="marketOpenBets.length + 3" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {marketOpenBets} from '@/configs/market.config'

export default {
  name: 'AccountSettingRateTable',
  props: ['data', 'refSetting'],
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    payrates() {
      return this.data.payRate
    },
    hasData() {
      return (Object.keys(this.payrates)||[]).length > 0
    }
  },
  methods: {
    groupName(groupId) {
      let group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.groupTitle
      }else{
        return groupId
      }
    },
    isGlobalActivate(rateId) {
      let rate = this.$store.state.marketPayrates.find((r)=>{
        return r._id === rateId
      })

      return rate && rate.status === 'Active'
    },
    rateName(rateId) {
      let rate = this.$store.state.marketPayrates.find((r)=>{
        return r._id === rateId
      })

      if(rate) {
        return rate.rateTitle
      }else{
        return rateId
      }
    },
    toString(number) {
      if(number)
        return parseInt(number).toString()
      return '0'
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  tbody {
    background-color: #FFF;
  }
  td {
    vertical-align: middle;
  }
}
</style>
